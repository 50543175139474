<template>
<div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

        <!-- Header: Left -->
        <div class="email-header-left d-flex align-items-center">
            <span class="go-back mr-1">
                <feather-icon :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'" size="20" class="align-bottom" @click="$emit('close-email-view')" />
            </span>
            <h4 class="email-subject mb-0">
                Campaign ID {{ emailViewData.campaign_id }}
            </h4>
        </div>

        <!-- Header: Right -->
        <div class="email-header-right ml-2 pl-1">

            <!-- Mark Starred isLoading-->
            <feather-icon icon="DownloadIcon" size="17" class="cursor-pointer" :class="{ 'text-warning fill-current': emailViewData.isStarred }" @click="downloadMessage" v-show="!isLoading" />
            <b-spinner small v-show="isLoading" /><small v-show="isLoading" id="loadingDownload"> Please wait...</small>
        </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="email-scroll-area scroll-area">

        <!-- Label Row -->
        <b-row>
            <b-col cols="12">
                <div class="email-label">
                    <b-badge v-for="(label) in emailViewData.labels" :key="label" pill class="text-capitalize mr-50" :variant="`light-${resolveLabelColor(label)}`">
                        {{ label }}
                    </b-badge>
                </div>
            </b-col>
        </b-row>

        <!-- Action: Show Earlier Message -->
        <b-row v-if="!showWholeThread && emailViewData.replies && emailViewData.replies.length" class="mb-1">
            <b-col cols="12">
                <b-link class="font-weight-bold" @click="showWholeThread = true">
                    View {{ emailViewData.replies.length }} Earlier Message{{ emailViewData.replies.length > 1 ? 's' : '' }}
                </b-link>
            </b-col>
        </b-row>

        <!-- Earlier Email Messages -->
        <template v-if="showWholeThread">
            <b-row v-for="threadMail in emailViewData.replies.slice().reverse()" :key="threadMail.id">
                <b-col cols="12">
                    <email-message-card :message="threadMail" />
                </b-col>
            </b-row>
        </template>

        <!-- Email Thread -->
        <b-row>
            <b-col cols="12">
                <email-message-card :message="emailViewData" />
            </b-col>
        </b-row>

        <!-- Email Actions: Reply or Forward -->

    </vue-perfect-scrollbar>
</div>
</template>

<script>
import {
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BSpinner
} from 'bootstrap-vue'

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
    ref,
    watch
} from '@vue/composition-api'
import useEmail from './useEmail'
import EmailMessageCard from './EmailMessageCard.vue'
import $ from 'jquery'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from "moment";
export default {
    components: {

        // BSV
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BBadge,
        BCard,
        BLink,

        // 3rd Party
        VuePerfectScrollbar,

        // SFC
        EmailMessageCard,
        BSpinner,
        ToastificationContent
    },
    props: {

        emailViewData: {
            type: Object,
            required: true,
        },
        opendedEmailMeta: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const {
            resolveLabelColor
        } = useEmail()

        const perfectScrollbarSettings = {
            maxScrollbarLength: 150,
        }

        const showWholeThread = ref(false)

        watch(() => props.emailViewData.id, () => {
            showWholeThread.value = false
        })

        return {

            // UI
            perfectScrollbarSettings,
            showWholeThread,

            // useEmail
            resolveLabelColor,
        }
    },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        downloadMessage() {
            console.log("am here " + this.emailViewData.campaign_id)
            let vm = this
            vm.isLoading = true
            $('#loadingDownload').html(' Please Wait ...');
            $.get({
                url: store.state.rootUrl + 'account/v1/view/bulk_analytics',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    campaignId: vm.emailViewData.campaign_id,
                    export: 1
                },
                success: function (response, status, jQxhr) {

                    if (response.data.code != 200) {
                       vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })

                    } else {
                        $('#loadingDownload').html(' Converting the document');
                        var exportRecipients = [];
                        var message = response.data.data.campaign_data.message
                        var short_code = response.data.data.campaign_data.short_code
                        for (var i = 0; i < response.data.data.summary_data.length; i++) {
                            var item = {};
                            item.msisdn = response.data.data.summary_data[i].msisdn;
                            item.short_code = short_code;
                            item.message = response.data.data.summary_data[i].message;
                            item.network = response.data.data.summary_data[i].network;
                            item.delivery = response.data.data.summary_data[i].delivery;
                            item.created_at = response.data.data.summary_data[i].created_at;
                            item.received_on = response.data.data.summary_data[i].received_on;
                            exportRecipients.push(item);
                        }
                        var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                        vm.JSONToCSVConvertor(exportRecipients, "BulkReport_"+exportDate, 1);
                        $('#loadingDownload').html(' Please wait...');
                        vm.isLoading = false
                         vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }
                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    var statustext = jQxhr.responseJSON.statusDescription;
                    var errorMessage = ""
                    if (!statustext) {
                        errorMessage = "An error occurred. Try again later.";
                    } else {
                        var messageError = ""
                        messageError = jQxhr.responseJSON.data.message
                        if (!messageError) {
                            errorMessage = statustext;
                        } else {
                            errorMessage = statustext + " " + messageError;
                        }

                    }
                    vm.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errorMessage,
                            icon: 'EditIcon',
                            variant: 'error',
                        },
                    })
                }
            });
        },
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "Liden_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
        },
    }
}
</script>

<style>

</style>
