<template>
    <b-modal id="compose-mail" :visible="shallShowEmailComposeModal" title="Compose Message" modal-class="modal-sticky" footer-class="d-flex justify-content-between" body-class="p-0" size="lg" no-fade hide-backdrop static @change="(val) => $emit('update:shall-show-email-compose-modal', val)">
        <!-- Modal Header -->
        <template #modal-header>
            <h5 class="modal-title">
                Compose Message
            </h5>
    
            <div class="modal-actions">
                <feather-icon icon="MinusIcon" class="cursor-pointer" @click="$emit('update:shall-show-email-compose-modal', false)" />
    
            </div>
</template>

    <!-- Modal Footer -->
<template #modal-footer>
    <!-- Footer: Left Content -->
    <div>
    
        <b-form-file v-model="file" placeholder="Send message via csv file" drop-placeholder="Drop file here..." accept=".csv" id="file_upload"/>
    </div>
    <!-- Footer: Right Content -->
    <div>
    
        <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="!isScheduleFalse" split :text="isloading?'Loading ...':'Send'" variant="primary" right @click="sendMessage">
    
            <b-dropdown-item @click="isScheduleFalse=true">
                Schedule
            </b-dropdown-item>
        </b-dropdown>
        <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-show="isScheduleFalse" split :text="isloading?'loading ...':'Schedule'" variant="primary" right @click="sendMessage">
    
            <b-dropdown-item @click="isScheduleFalse=false">
                Send Now
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

    <!-- Modal: Body -->
    <b-form>
        <!-- Field: Bcc (Hidden Initially) -->
        <div class="compose-mail-form-field">
            <label for="email-bcc">From</label>
            <v-select v-model="senderID" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" class="flex-grow-1 email-bcc-selector" :close-on-select="true" :options="senderIDS" input-id="email-bcc">

<template #option="{ value, name }">
    <span class="ml-50" :id="value"> {{ name }}</span>
</template>

<template #selected-option="{ value, name }">
    <span class="ml-50" :id="value"> {{ name }}</span>
</template>
            </v-select>
        </div>
        <!-- Field: To -->
        <div class="compose-mail-form-field">
            <label for="email-to" class="form-label">To: </label>
            <b-form-tags v-model="phoneNumbers" input-id="tags-state-event" :tag-validator="validator" placeholder="Enter Phone separated by space" separator=" " />

            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="showCcField=!showCcField">
                <span v-show="!showCcField">Click to Select Contacts</span>
                <span v-show="showCcField">Click to UnSelect Contacts</span>
            </b-button>

        </div>
        <!-- Field: Cc - also refer to Contact Group (Hidden Initially) -->
        <div v-show="showCcField" class="compose-mail-form-field">
            <label for="email-cc">Select Contact: </label>
            <v-select v-model="contacts" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" multiple label="name" class="flex-grow-1 email-cc-selector" :close-on-select="false" :options="contactOption" input-id="email-cc">

<template #option="{ avatar, name }">
    <b-avatar size="sm" :src="avatar" />
    <span class="ml-50"> {{ name }}</span>
</template>

<template #selected-option="{ avatar, name }">
    <b-avatar size="sm" class="border border-white" :src="avatar" />
    <span class="ml-50"> {{ name }}</span>
</template>
            </v-select>
        </div>
        <div v-show="showCcField" class="compose-mail-form-field">
            <label for="email-cc" class="text-primary">Campaign: </label>
            <b-form-input id="basicInput" v-model="campaignName" placeholder="Enter Campaign Name... Optional" />
        </div>
       
        <!-- Field: Message - Quill Editor -->
        <div class="message-editor">
            <b-row ref="row" style="margin-left: 20px" v-show="showTags">
                <b-col lg="1" md="1" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="addTagsPlacehOlders('m')" >
                        M
                    </b-button>
                </b-col>
                <b-col lg="1" md="1" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="addTagsPlacehOlders('f')">
                        F
                    </b-button>
                </b-col>
                <b-col lg="1" md="1" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="addTagsPlacehOlders('c1')">
                        C1
                    </b-button>
                </b-col>
                <b-col lg="1" md="1" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="addTagsPlacehOlders('c2')">
                        C2
                    </b-button>
                </b-col>
                <b-col lg="1" md="1" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="addTagsPlacehOlders('c3')">
                        C3
                    </b-button>
                </b-col>
                <b-col lg="1" md="1" class="mb-50">
                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="addTagsPlacehOlders('c4')">
                        C4
                    </b-button>
                </b-col>
            </b-row>
            <br />
            <b-row ref="row" style="margin-left: 20px" v-show="showTags">
                <vue-good-table :columns="columns" :rows="rows"                   :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }">
<template slot="table-row" slot-scope="props">
    <!-- Column: Name -->
    <div v-if="props.column.field === 'fullName'" class="text-nowrap">
        <b-avatar :src="props.row.avatar" class="mx-1" />
        <span class="text-nowrap">{{ props.row.fullName }}</span>
    </div>
    
    <!-- Column: Status -->
    <span v-else-if="props.column.field === 'status'">
                                    <b-badge :variant="props.row.status | shortCodeStatusColor">
                                        {{ props.row.status | shortcodeStatus}}
                                    </b-badge>
                                </span>
    
    <!-- Column: Action -->
    <span v-else-if="props.column.field === 'action'">
                                    <span>
                                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                            <template v-slot:button-content>
                                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
</template>
                                        <b-dropdown-item>
                                            <feather-icon icon="Edit2Icon" class="mr-50" />
                                            <span>Disabled</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item>
                                            <feather-icon icon="TrashIcon" class="mr-50" />
                                            <span>Delete</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </span>
                            </span>
            
                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
            
                        <!-- pagination -->
                       
                    </vue-good-table>
            </b-row>
            <b-col cols="6" class="mb-2" v-show="showTags"><h4>Preview</h4> <p>{{ sampleMessage }}</p></b-col>
           
          
            <textarea rows="12" style="width:100%;border:0px solid #999999; padding:20px" placeholder="Type your SMS here." v-model="message"></textarea>

            <div id="quill-toolbar" class="d-flex border-bottom-0">

                <!-- Add a bold button -->

                <!-- <b-card-text class="mb-0" style="margin-left:5px">
                 <b-form-checkbox
                :checked="isStop"
                class="custom-control-primary"
                name="check-button"
                v-model="isStop"
                switch
            >
            <quill-editor id="quil-content" v-model="message" :options="editorOption" @change="onEditorChange($event)" ref="myQuillEditor" class='ql-editor'  />
                <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
                </span> 
            </b-form-checkbox> 
                RCS 
            </b-card-text>
            <b-form-checkbox
                :checked="isRCS"
                class="custom-control-primary"
                name="check-button"
                v-model="isRCS"
                switch
            >
                <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
                </span> 
            </b-form-checkbox> -->

                <button class="ql-bold" v-show="isRCS" />
                <button class="ql-italic" v-show="isRCS" />
                <button class="ql-align" v-show="isRCS" />
                <button class="ql-link" v-show="isRCS" />
                <div style="margin-left:40px">
                    Total Character {{smsCount}} <span style="text-align:right"> Total SMS {{messagePages}} </span>
                </div>

            </div>
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
        </div>
    </b-form>
    <b-row v-show="isScheduleFalse" style="margin:0 5px 0 5px">
        <b-col cols="6" class="mb-2">
            <b-form-group>
                <small>Schedule Date</small>
                <flat-pickr v-model="scheduleDate" class="form-control" :config="{ altInput: true,altFormat: 'F j, Y', dateFormat: 'Y-m-d',}" />
            </b-form-group>
        </b-col>
        <b-col md="6">
            <b-form-group>
                <small>Schedule Time</small>
                <input type="time" v-model="scheduleTime" class="form-control"  />
            </b-form-group>
        </b-col>
    </b-row>

</b-modal>
</template>

<script>
import {
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BFormTags,
    BSpinner,
    BAlert,
    BCardText,
    BFormCheckbox,
    BButton,
    BFormTextarea
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
    ref
} from '@vue/composition-api'
import {
    quillEditor
} from 'vue-quill-editor'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import $ from 'jquery'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    heightFade
} from '@core/directives/animations'
export default {
    directives: {
        Ripple,
        heightFade
    },
    data() {
        return {
            isRCS: false,
            file: null,
            showTags: false,
            scheduleDate: "",
            scheduleTime: "",
            pageLength: 3,
            isScheduleFalse: 0,
            senderID: [],
            contacts: [],
            senderIDS: [],
            phoneNumbers: [],
            contactOption: [],
            message: "",
            dismissSecs: 5,
            dismissCountDown: 0,
            showDismissibleAlert: false,
            errorMessage: "",
            isloading: false,
            maxCharater: 800,
            endMessage: "",
            campaignName: "",
            isStop: true,
            columns: [{
                    label: 'Mobile',
                    field: 'mobile',

                },
                {
                    label: 'Full Name',
                    field: 'full_names'
                },
                {
                    label: 'Custom 1',
                    field: 'custom1',
                },

                {
                    label: 'Custom 2',
                    field: 'custom2',
                },
                {
                    label: 'Custom 3',
                    field: 'custom3', 
                },
                {
                    label: 'Custom 4',
                    field: 'custom4',
                },
            ],
            rows: [],
            selectedRow:null,
            sampleMessage:""

        }
    },
    components: {
        // BSV
        BDropdown,
        BDropdownItem,
        BForm,
        BFormInput,
        BAvatar,
        BDropdownDivider,
        flatPickr,
        BRow,
        BCol,
        BFormGroup,
        BFormFile,
        BFormTags,
        // 3rd Party
        quillEditor,
        vSelect,
        BSpinner,
        BAlert,
        BFormCheckbox,
        BCardText,
        BButton,
        BFormTextarea
    },
    model: {
        prop: 'shallShowEmailComposeModal',
        event: 'update:shall-show-email-compose-modal',
    },
    props: {
        shallShowEmailComposeModal: {
            type: Boolean,
            required: true,
        },
    },
    setup(_, {
        emit
    }) {
        const composeData = ref({})
        const showCcField = ref(false)
        const showBccField = ref(false)

        const editorOption = {
            modules: {
                toolbar: '#quill-toolbar',
            },
            placeholder: 'Message',
        }

        /* eslint-disable global-require */

        /* eslint-enable global-require */

        return {
            composeData,
            editorOption,
            showCcField,
            showBccField,

        }
    },

    mounted() {
        this.getContacts()
        this.getSenderID()
        if (this.isStop) {
            //this.endMessage = " STOP*456*9*5#";
        }

    },
    computed: {
        smsCount() {
            this.sampleMessage = this.message
            if(this.selectedRow != null){
                this.sampleMessage = this.sampleMessage.replace("{m}", this.selectedRow.mobile);
                this.sampleMessage = this.sampleMessage.replace("{f}", this.selectedRow.full_names);
                this.sampleMessage = this.sampleMessage.replace("{c1}", this.selectedRow.custom1);
                this.sampleMessage = this.sampleMessage.replace("{c2}", this.selectedRow.custom2);
                this.sampleMessage = this.sampleMessage.replace("{c3}", this.selectedRow.custom3);
                this.sampleMessage = this.sampleMessage.replace("{c4}", this.selectedRow.custom4);
            }

            return this.message.length + this.endMessage.length
        },
        messagePages() {
            if (this.smsCount == 0) {
                return 0;
            } else if (this.smsCount / 160 <= 1) {
                return 1;
            } else if (this.smsCount / 160 <= 2) {
                return 2;
            } else if (this.smsCount / 160 <= 3) {
                return 3;
            } else if (this.smsCount / 160 <= 4) {
                return 4;
            } else if (this.smsCount / 160 <= 5) {
                return 5;
            } else {
                return " Max ";
            }
        },

    },
    methods: {
        addTagsPlacehOlders(tag) {
            this.message = this.message + " {" + tag + "}";
          
        },
        onEditorChange(event) {
            event.quill.deleteText(2000, 1);
            if (this.message === 0) {
                this.smsCount = 0
            } else {
                this.smsCount = event.quill.getLength() - 1
            }
        },

        escapeStringHTML(str) {
            str = str.replace(/(<([^>]+)>)/gi, "");
            return str;
        },

        onPhoneState(valid, invalid, duplicate) {
            console.log("valid:  " + valid + " invalid: " + invalid + " duplicate:  " + duplicate)
        },
        validator(phoneNumber) {
            return phoneNumber.length > 9 && phoneNumber.length < 12
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        escapeHtml(html) {
            var text = document.createTextNode(html);
            var p = document.createElement('p');
            p.appendChild(text);
            return p.innerHTML;
        },
        sendMessage() {
            let vm = this
            vm.isloading = true
            //console.log("Message Test " + vm.message)
            //vm.message = vm.message.split("<br>").join("{line}");
            //     if(this.isStop){
            //     this.endMessage = " STOP*456*9*5#";
            // } else {
            //     this.endMessage = "";
            // }

            $('#sendMSG').html(' Please wait ...');
            if (vm.contacts.length > 0) {
                var listIDs = ""
                vm.contacts.forEach((contact, index) => {
                    if (index == 0) {
                        listIDs = contact.value
                    } else {
                        listIDs = listIDs + "," + contact.value
                    }

                });
                $.ajax({
                    url: store.state.rootUrl + 'sms/v1/bulk/group_create',
                    type: "POST",
                    data: JSON.stringify({
                        "shortCode": vm.senderID.value,
                        "message": vm.escapeStringHTML(vm.message) + "" + vm.endMessage,
                        "listId": listIDs,
                        "campaignName": vm.campaignName,
                        "isScheduled": vm.isScheduleFalse,
                        "scheduleDate": vm.scheduleDate,
                        "scheduleTime": vm.scheduleTime
                    }),
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    cache: false,
                    contentType: false,
                    processData: false,
                    success: function(response, status, jQxhr) {
                        vm.isloading = false
                        if (vm.isScheduleFalse) {
                            $('#sendMSG').html(' Schedule');
                        } else {
                            $('#sendMSG').html(' Send Now');
                        }
                        if (response.data.code != 200) {
                            // console.log("error message " + response.data.message)
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$emit('update:shall-show-email-compose-modal', false)
                            vm.$parent.getMessage()
                        }

                    },
                    error: function(jQxhr, status, error) {
                        if (vm.isScheduleFalse) {
                            $('#sendMSG').html(' Schedule');
                        } else {
                            $('#sendMSG').html(' Send Now');
                        }
                        vm.isloading = false
                        vm.dismissCountDown = vm.dismissSecs
                        if (jQxhr.responseJSON.code == 'Error') {
                            vm.errorMessage = jQxhr.responseJSON.statusDescription;
                        } else {
                            if (jQxhr.responseJSON.data.code == 401 ||
                                jQxhr.responseJSON.data.code == 500 ||
                                jQxhr.responseJSON.data.code == 403 ||
                                jQxhr.responseJSON.data.code == 404) {
                                vm.$cookies.remove("token");
                                vm.$cookies.remove("clientData");
                                // Redirect to login page
                                vm.$router.push({
                                    name: 'login'
                                })
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: jQxhr.responseJSON.data.message,
                                        icon: 'EditIcon',
                                        variant: 'failed',
                                    },
                                })
                            }

                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                if (jQxhr.responseJSON.data) {
                                    vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                                } else {
                                    vm.errorMessage = statustext
                                }

                            }
                        }

                    }
                });
            }
            if (vm.phoneNumbers.length > 0) {
                $.ajax({
                    url: store.state.rootUrl + 'sms/v1/bulk/api_submit',
                    type: "POST",
                    data: JSON.stringify({
                        "shortCode": vm.senderID.value,
                        "message": vm.escapeStringHTML(vm.message) + "" + vm.endMessage,
                        "recipients": vm.phoneNumbers.join(),
                        "uniqueId": vm.phoneNumbers.join(),
                        "enqueue": "1",
                        "isScheduled": vm.isScheduleFalse,
                        "scheduleDate": vm.scheduleDate,
                        "scheduleTime": vm.scheduleTime

                    }),
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    cache: false,
                    contentType: false,
                    processData: false,

                    success: function(response, status, jQxhr) {
                        vm.isloading = false
                        if (vm.isScheduleFalse) {
                            $('#sendMSG').html(' Schedule');
                        } else {
                            $('#sendMSG').html(' Send Now');
                        }
                        if (response.data.code != 200) {
                            //console.log("error message " + response.data.message)
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$emit('update:shall-show-email-compose-modal', false)
                            vm.$parent.getMessage()

                        }

                    },
                    error: function(jQxhr, status, error) {
                        if (vm.isScheduleFalse) {
                            $('#sendMSG').html(' Schedule');
                        } else {
                            $('#sendMSG').html(' Send Now');
                        }
                        vm.isloading = false
                        vm.dismissCountDown = vm.dismissSecs
                        if (jQxhr.responseJSON.code == 'Error') {
                            vm.errorMessage = jQxhr.responseJSON.statusDescription;
                        } else {
                            if (jQxhr.responseJSON.data.code == 401 ||
                                jQxhr.responseJSON.data.code == 500 ||
                                jQxhr.responseJSON.data.code == 403 ||
                                jQxhr.responseJSON.data.code == 404) {
                                vm.$cookies.remove("token");
                                vm.$cookies.remove("clientData");
                                // Redirect to login page
                                vm.$router.push({
                                    name: 'login'
                                })
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: jQxhr.responseJSON.data.message,
                                        icon: 'EditIcon',
                                        variant: 'failed',
                                    },
                                })
                            }

                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                if (jQxhr.responseJSON.data) {
                                    vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                                } else {
                                    vm.errorMessage = statustext
                                }

                            }
                        }
                    }
                });
            }
            if (vm.file != null) {
                var data = new FormData();
                data.append("shortCode", vm.senderID.value);
                data.append("message", vm.escapeStringHTML(vm.message) + "" + vm.endMessage);
                data.append("isScheduled", vm.isScheduleFalse);
                data.append("scheduleDate", vm.scheduleDate);
                data.append("scheduleTime", vm.scheduleTime);
                data.append("uniqueId", vm.senderID.value);
                data.append("isBlast", 0);
                data.append("uploadedFile", vm.file);
                $.ajax({
                    url: store.state.rootUrl + 'sms/v1/bulk_broadcast',
                    type: "POST",
                    data: data,
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    cache: false,
                    contentType: false,
                    processData: false,

                    success: function(response, status, jQxhr) {
                        vm.isloading = false
                        vm.file == null
                        if (vm.isScheduleFalse) {
                            $('#sendMSG').html(' Schedule');
                        } else {
                            $('#sendMSG').html(' Send Now');
                        }
                        if (response.data.code != 200) {
                            //console.log("error message " + response.data.message)
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message

                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.$emit('update:shall-show-email-compose-modal', false)
                            vm.$parent.getMessage()
                        }

                    },
                    error: function(jQxhr, status, error) {
                        if (vm.isScheduleFalse) {
                            $('#sendMSG').html(' Schedule');
                        } else {
                            $('#sendMSG').html(' Send Now');
                        }
                        vm.isloading = false
                        vm.dismissCountDown = vm.dismissSecs
                        if (jQxhr.responseJSON.code == 'Error') {
                            vm.errorMessage = jQxhr.responseJSON.statusDescription;
                        } else {
                            if (jQxhr.responseJSON.data.code == 401 ||
                                jQxhr.responseJSON.data.code == 500 ||
                                jQxhr.responseJSON.data.code == 403 ||
                                jQxhr.responseJSON.data.code == 404) {
                                vm.$cookies.remove("token");
                                vm.$cookies.remove("clientData");
                                // Redirect to login page
                                vm.$router.push({
                                    name: 'login'
                                })
                                vm.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: jQxhr.responseJSON.data.message,
                                        icon: 'EditIcon',
                                        variant: 'failed',
                                    },
                                })
                            }

                            var statustext = jQxhr.responseJSON.statusDescription;
                            vm.showDismissibleAlert = true;
                            if (!statustext) {
                                vm.errorMessage = "An error occurred. Try again later.";
                            } else {
                                if (jQxhr.responseJSON.data) {
                                    vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                                } else {
                                    vm.errorMessage = statustext
                                }

                            }
                        }
                    }
                });
            }
            if (vm.contacts.length == 0 && vm.phoneNumbers.length == 0 && vm.file == null) {
                vm.dismissCountDown = vm.dismissSecs
                vm.showDismissibleAlert = true
                vm.isloading = false
                vm.errorMessage = "You need to enter atleast one Phone number or Select contact or Upload file to send message"
            }
            vm.senderID = []
            vm.contacts = []
            vm.phoneNumbers = []
            vm.message = ""

        },
        getSenderID() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'account/v1/view/sender_ids',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    limit: 50,
                    typeId: "3,4",
                    status: true
                },
                success: function(response, status, jQxhr) {

                    if (response.data.code != 200) {
                        vm.senderIDS = [{
                            value: 'LidenCo',
                            name: 'LidenCo'
                        }]

                    } else {
                        let result = response.data.data;
                        for (var i = 0; i < result.length; i++) {
                            var contact = {
                                value: result[i].short_code,
                                name: result[i].short_code,

                            }
                            vm.senderIDS.push(contact)
                        }
                    }
                    //console.log(JSON.stringify(vm.senderIDS))
                },
                error: function(jQxhr, status, error) {
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({
                            name: 'login'
                        })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }

                }
            });
        },

        getContacts() {
            let vm = this
            $.get({
                url: store.state.rootUrl + 'contact/v1/view/groups',
                type: "get",
                async: true,
                crossDomain: true,
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "XMLHttpRequest",
                    "X-Authorization-Key": vm.$cookies.get("token"),
                },
                data: {
                    limit: 1000
                },
                success: function(response, status, jQxhr) {

                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {

                    } else {
                        let result = response.data.data;
                        for (var i = 0; i < result.length; i++) {
                            if (result[i].status == 1) {
                                var contact = {
                                    avatar: "",
                                    value: result[i].list_id,
                                    name: result[i].group_name,

                                }
                                vm.contactOption.push(contact)
                            }
                        }
                    }
                },
                error: function(jQxhr, status, error) {
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({
                            name: 'login'
                        })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }
                }
            });
        },
        csvFileToJSON(file) {
            try {
                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = function(e) {
                    var jsonData = [];
                    var headers = [];
                    var rows = e.target.result.split("\r\n");
                    var total = 3;
                    if(rows.length < 3){
                        var total = rows.length;
                    }
                    for (var i = 0; i < total; i++) {
                        var cells = rows[i].split(",");
                        var rowData = {};
                        for (var j = 0; j < cells.length; j++) {
                            if (i == 0) {
                                var headerName = cells[j].trim();
                                headers.push(headerName);
                            } else {
                                var key = headers[j];
                                if (key) {
                                    rowData[key] = cells[j].trim();
                                }
                            }
                        }
                        //skip the first row (header) data
                        if (i != 0) {
                            jsonData.push(rowData);
                        }
                    }

                    //displaying the json result in string format
                    console.log("Data"+JSON.stringify(jsonData));
                }
            } catch (e) {
                console.error(e);
            }
        }
    },
    watch: {
        file: function(val) {
            
            let vm  = this
            var files = document.getElementById('file_upload').files;
            var filename = files[0].name;
            var extension = filename.substring(filename.lastIndexOf(".")).toUpperCase();
            if (extension == '.CSV') {
                //Here calling another method to read CSV file into json

                var file = files[0];
                try {
                var reader = new FileReader();
                reader.readAsBinaryString(file);
                reader.onload = function(e) {
                    var jsonData = [];
                    var headers = [];
                    var rows = e.target.result.split("\r\n");
                    var total = 3;
                    if(rows.length < 3){
                        var total = rows.length;
                    }
                    for (var i = 0; i < total; i++) {
                        var cells = rows[i].split(",");
                        var rowData = {};
                        for (var j = 0; j < cells.length; j++) {
                            if (i == 0) {
                                var headerName = cells[j].trim();
                                headers.push(headerName);
                            } else {
                                var key = headers[j];
                                if (key) {
                                    rowData[key] = cells[j].trim();
                                }
                            }
                        }
                        //skip the first row (header) data
                        if (i != 0) {
                            jsonData.push(rowData);
                        }
                    }
                    vm.showTags = true
                    //displaying the json result in string format
                    console.log("Data"+JSON.stringify(jsonData));
                    vm.rows = jsonData
                    vm.selectedRow = jsonData[0]
                }
            } catch (e) {
                console.error(e);
            }
            }
            console.log(" am here");
        },
        // whenever question changes, this function will run
        question(senderID, oldQuestion) {
            if (senderID.value === 'kabakabogo') {
                this.endMessage = " STOP*456*9*9#"
                console.log(this.endMessage)
            }
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
textarea:focus {
    border: 0;
}

form ::v-deep {
    // Mail To vue-select style
    .v-select {
        .vs__dropdown-toggle {
            border: 0;
            box-shadow: none;
        }
        .vs__open-indicator {
            display: none;
        }
    } // Quill Editor Style
    .quill-editor {
        .ql-container.ql-snow {
            border-bottom: 0 !important;
        }
    }
}
</style>
